import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function NotificationModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>수업 안내</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        안녕하세요 드림필라테스 입니다.
        <br />
        <br />
        추석 이후 9월 19일 부터, 월요일과 목요일, 오전 10시와 11시, 그룹수업이
        추가 개설 됩니다.
        <br />
        수업을 담당하는 이소영 강사님의 오픈 클래스를 진행하게 되어
        안내드립니다!
        <br />
        <br />
        일시: 2024년 9월 5일 목요일 오전 10시
        <br />
        오픈 클래스는 횟수 차감이 되지 않습니다.
        <br />
        관심 있는 회원님들의 많은 이용 부탁드립니다!
      </Modal.Body>

      {/* <Modal.Body>
        <div className="NotificationModal">
          <img src="https://storage.googleapis.com/storage.cocore.co.kr/images/Dream_noti_20240111.jpeg" />
        </div>
      </Modal.Body> */}

      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotificationModal;
