import React, { Component } from 'react';
import { ButtonToolbar, Button } from 'react-bootstrap';
import moment from 'moment';

//import components
import LogoBox from '../components/LogoBox';
import NotificationModal from '../components/NotificationModal';
import { serviceConfigs } from '../configs/Configs';

export class MainPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNoti: true,
    };
  }

  handleNotiClose() {
    this.setState({
      showNoti: false,
    });
  }

  render() {
    let notification = null;

    if (
      this.state.showNoti &&
      serviceConfigs.serviceShortName === 'DREAM' &&
      localStorage.getItem('branchId') == 2 &&
      moment().isBefore('2024-09-04 12:00:00')
    ) {
      notification = (
        <NotificationModal
          show={this.state.showNoti}
          handleClose={this.handleNotiClose.bind(this)}
        />
      );
    } else {
      notification = null;
    }

    return (
      <div className="main">
        {notification}
        <LogoBox />
        <div className="MainBox">
          <ButtonToolbar>
            <Button variant="outline-dark" block href="/book">
              수업 예약
            </Button>
            <Button variant="outline-info" block href="/booked">
              예약된 수업 관리
            </Button>
            <Button variant="outline-info" block href="/sessions">
              완료된 수업 확인
            </Button>
            <Button variant="outline-info" block href="/info">
              내 정보 확인
            </Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

export default MainPage;
